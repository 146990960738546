<template>
  <div class="mt-10 background-color">
    <session-detail-page v-if="canManageRelease"></session-detail-page>
    <no-data v-else with-button first-text="You don't have permissions to view sessions" />
  </div>
</template>

<script>
import SessionDetailPage from "@/view/components/Sessions/SessionDetailPage/index.vue";

export default {
  components: { SessionDetailPage },
};
</script>
