<template>
  <v-tab v-if="!locked" @click.stop="handleClick">
    <span class="darkGrey--text">
      {{ label }}
    </span>
  </v-tab>
  <v-tooltip v-else bottom>
    <template #activator="{ on }">
      <v-tab
        :disabled="locked || loading"
        :ripple="false"
        style="pointer-events: auto !important; cursor: default"
        v-on="on"
      >
        <span class="darkGrey--text">{{ label }}</span>
        <v-icon v-if="locked" color="primary" size="12" style="margin-left: 4px"
          >mdi-lock-outline
        </v-icon>
      </v-tab>
    </template>
    <span>Coming soon</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (!this.locked) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.removeBackground {
  background-color: white;
}
</style>
