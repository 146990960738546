var render = function render(){
  var _vm$sessionDetail, _vm$sessionDetail$use, _vm$sessionDetail2, _vm$sessionDetail2$us, _vm$sessionDetail3, _vm$sessionDetail4, _vm$sessionDetail5, _vm$sessionDetail5$co, _vm$sessionDetail5$co2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.sessionErrorMessage ? _c('div', [_c('no-data', {
    attrs: {
      "first-text": "Ops!",
      "second-text": _vm.sessionErrorMessage
    }
  })], 1) : [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('ProfileImage', {
    attrs: {
      "alt": (_vm$sessionDetail = _vm.sessionDetail) === null || _vm$sessionDetail === void 0 ? void 0 : (_vm$sessionDetail$use = _vm$sessionDetail.user) === null || _vm$sessionDetail$use === void 0 ? void 0 : _vm$sessionDetail$use.name,
      "image": (_vm$sessionDetail2 = _vm.sessionDetail) === null || _vm$sessionDetail2 === void 0 ? void 0 : (_vm$sessionDetail2$us = _vm$sessionDetail2.user) === null || _vm$sessionDetail2$us === void 0 ? void 0 : _vm$sessionDetail2$us.image
    }
  })], 1), _c('v-col', {
    staticClass: "darkGrey--text"
  }, [_c('div', {
    staticClass: "font24"
  }, [_vm._v(" " + _vm._s((_vm$sessionDetail3 = _vm.sessionDetail) !== null && _vm$sessionDetail3 !== void 0 && _vm$sessionDetail3.id ? (_vm$sessionDetail4 = _vm.sessionDetail) === null || _vm$sessionDetail4 === void 0 ? void 0 : _vm$sessionDetail4.id : "Anonymous") + " ")]), _c('div', {
    staticClass: "font14"
  }, [_vm._v(" " + _vm._s((_vm$sessionDetail5 = _vm.sessionDetail) === null || _vm$sessionDetail5 === void 0 ? void 0 : (_vm$sessionDetail5$co = _vm$sessionDetail5.context) === null || _vm$sessionDetail5$co === void 0 ? void 0 : (_vm$sessionDetail5$co2 = _vm$sessionDetail5$co.app) === null || _vm$sessionDetail5$co2 === void 0 ? void 0 : _vm$sessionDetail5$co2.name) + " ")])])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-3",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabs, function (value, key) {
    return _c('tab', {
      key: key,
      attrs: {
        "label": value.label,
        "locked": value.locked
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: "session-details-".concat(value.value)
          });
        }
      }
    });
  }), 1), _c('v-btn', {
    staticStyle: {
      "position": "absolute",
      "right": "0",
      "top": "50%",
      "transform": "translateY(-50%)"
    },
    attrs: {
      "color": "primary",
      "small": "",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.drawerState = true;
      }
    }
  }, [_vm._v("Session Details ")])], 1), _c('router-view')], 1)], 1), _c('v-navigation-drawer', {
    staticClass: "session-drawer",
    attrs: {
      "fixed": "",
      "overlay-opacity": "0",
      "right": "",
      "temporary": ""
    },
    model: {
      value: _vm.drawerState,
      callback: function callback($$v) {
        _vm.drawerState = $$v;
      },
      expression: "drawerState"
    }
  }, [_c('session-drawer', {
    attrs: {
      "session-detail": _vm.sessionDetail
    }
  })], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }