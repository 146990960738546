var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-10 background-color"
  }, [_vm.canManageRelease ? _c('session-detail-page') : _c('no-data', {
    attrs: {
      "with-button": "",
      "first-text": "You don't have permissions to view sessions"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }