<template>
  <div>
    <div v-if="sessionErrorMessage">
      <no-data first-text="Ops!" :second-text="sessionErrorMessage"></no-data>
    </div>
    <template v-else>
      <v-row align="center">
        <v-col cols="3">
          <v-row align="center">
            <v-col cols="auto">
              <ProfileImage
                :alt="sessionDetail?.user?.name"
                :image="sessionDetail?.user?.image"
              ></ProfileImage>
            </v-col>
            <v-col class="darkGrey--text">
              <div class="font24">
                {{ sessionDetail?.id ? sessionDetail?.id : "Anonymous" }}
              </div>
              <div class="font14">
                {{ sessionDetail?.context?.app?.name }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="mb-3" style="position: relative">
            <v-tabs v-model="tab">
              <tab
                v-for="(value, key) in tabs"
                :key="key"
                :label="value.label"
                :locked="value.locked"
                @click="
                  $router.push({ name: `session-details-${value.value}` })
                "
              />
            </v-tabs>
            <v-btn
              color="primary"
              small
              style="
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
              "
              :outlined="$vuetify.theme.dark"
              @click.stop="drawerState = true"
              >Session Details
            </v-btn>
          </div>

          <router-view></router-view>
        </v-col>
      </v-row>

      <v-navigation-drawer
        v-model="drawerState"
        class="session-drawer"
        fixed
        overlay-opacity="0"
        right
        temporary
      >
        <session-drawer :session-detail="sessionDetail"></session-drawer>
      </v-navigation-drawer>
    </template>
  </div>
</template>

<script>
import { GET_SESSIONS_DETAIL } from "@/store/apps/sessions.module";
import SessionDrawer from "@/view/components/Sessions/SessionDetailPage/SessionDrawer.vue";
import Tab from "@/view/components/Common/Tab.vue";
import { mapMutations, mapState } from "vuex";

const tabs = {
  overview: {
    label: "Overview",
    value: "overview",
  },
  activities: {
    label: "Activities",
    value: "activities",
  },
  feedback: {
    label: "Feedback",
    value: "feedback",
  },
  network: {
    label: "Network",
    value: "network",
    locked: true,
  },
  timeline: {
    label: "Timeline",
    value: "timeline",
    locked: true,
  },
};
const tabsValue = {
  "session-details-overview": 0,
  "session-details-activities": 1,
  "session-details-feedback": 2,
};
export default {
  props: ["app_id", "session_id", "feedback_id"],
  components: {
    SessionDrawer,
    Tab,
  },
  data() {
    return {
      tabs,
      sessionDetail: {},
      loading: false,
      sessionErrorMessage: null,
      tab: this.defaultTab,
    };
  },
  created() {
    this.getAllSessionData();

    const activeTab = this.$route.params.feedback_id
      ? this.tabs.feedback.value
      : this.$route.path.split("/").pop();
    this.tab = this.tabList.findIndex(
      (tab) => tab.value === (activeTab ?? this.defaultTab)
    );
  },
  computed: {
    ...mapState({
      showSessionDetails: (state) => state.sessions.showSessionDetails,
    }),
    drawerState: {
      get() {
        return this.showSessionDetails;
      },
      set(value) {
        this.toggleSessionDetails(value); // Commit mutation to change the drawer state
      },
    },
    tabList() {
      return Object.values(this.tabs);
    },
    defaultTab() {
      return this.tabList[0].value;
    },
  },
  watch: {
    "$route.name"(val) {
      this.tab = tabsValue[val];
      if (this.$route.params.session_id !== this.sessionDetail.id) {
        this.getAllSessionData();
      }
    },
  },
  methods: {
    ...mapMutations({
      toggleSessionDetails: "toggleSessionDetails",
    }),
    getAllSessionData() {
      this.sessionErrorMessage = null;
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_SESSIONS_DETAIL, {
          app_id: this.$route.params.app_id,
          session_id: this.$route.params.session_id,
          timestamp: "",
        })
        .then((response) => {
          this.sessionDetail = response?.data;
          this.loading = false;
        })
        .catch((err) => {
          this.sessionErrorMessage = err.message;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.session-drawer {
  border-radius: 8px;
  bottom: 20px !important;
  height: auto !important;
  max-width: 500px !important;
  min-width: 400px !important;
  padding: 8px 0;
  right: 20px !important;
  top: 20px !important;
  width: auto !important;
}
</style>
