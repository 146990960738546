var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.locked ? _c('v-tab', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "darkGrey--text"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])]) : _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-tab', _vm._g({
          staticStyle: {
            "pointer-events": "auto !important",
            "cursor": "default"
          },
          attrs: {
            "disabled": _vm.locked || _vm.loading,
            "ripple": false
          }
        }, on), [_c('span', {
          staticClass: "darkGrey--text"
        }, [_vm._v(_vm._s(_vm.label))]), _vm.locked ? _c('v-icon', {
          staticStyle: {
            "margin-left": "4px"
          },
          attrs: {
            "color": "primary",
            "size": "12"
          }
        }, [_vm._v("mdi-lock-outline ")]) : _vm._e()], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Coming soon")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }