<template>
  <v-row class="darkGrey--text">
    <v-col cols="12">
      <div class="px-5 pt-5">
        <span class="subTitle">Session Details</span>
      </div>
    </v-col>
    <v-col class="py-0" cols="12">
      <v-divider></v-divider>
    </v-col>

    <!--  LOCATION -->
    <v-col :cols="fromOverview ? 6 : 12">
      <div class="mb-3 px-5 pt-5 pb-5 semi-bold">
        LOCATION
        <v-btn @click="showLocationHelp = true" icon>
          <v-icon v-text="'mdi-help-circle-outline'"></v-icon>
        </v-btn>
      </div>
      <!-- <v-row class="projectDetails-items pt-5 px-5">
        <v-col class="pa-0 px-5" cols="5"> IP:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.ip }}
        </v-col>
      </v-row> -->

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> Country:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          <flex-row gap="4px">
            <flag :code="sessionDetail?.location?.country_code" />
            <span
              >{{ sessionDetail?.location?.country }} ({{
                sessionDetail?.location?.country_code
              }})</span
            >
          </flex-row>
        </v-col>
      </v-row>

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> City:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.location?.city }}
        </v-col>
      </v-row>
    </v-col>

    <v-col v-if="!fromOverview" class="py-0 pt-5" cols="12">
      <v-divider></v-divider>
    </v-col>

    <!--  IDENTIFIERS -->
    <v-col :cols="fromOverview ? 6 : 12">
      <div class="mb-3 px-5 pt-5 pb-5 semi-bold">
        IDENTIFIERS
        <v-btn @click="showIdentifierHelp = true" icon>
          <v-icon v-text="'mdi-help-circle-outline'"></v-icon>
        </v-btn>
      </div>
      <v-row class="projectDetails-items pt-5 px-5">
        <v-col class="pa-0 px-5" cols="5"> Identity:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          <span v-if="sessionDetail?.user?.identity">{{
            sessionDetail?.user?.identity
          }}</span>
          <empty v-else />
        </v-col>
      </v-row>
      <v-row
        v-if="sessionDetail?.user?.email"
        class="projectDetails-items pt-5 px-5"
      >
        <v-col class="pa-0 px-5" cols="5">Email:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          <span v-if="sessionDetail?.user?.email">{{
            sessionDetail.user.email
          }}</span>
          <empty v-else />
        </v-col>
      </v-row>

      <v-row class="projectDetails-items pt-5 px-5">
        <v-col class="pa-0 px-5" cols="5">Name:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          <MaxText
            v-if="
              sessionDetail?.user?.name ||
              sessionDetail?.anonymous_id === sessionDetail?.user?.identity
            "
            :text="sessionDetail?.user?.name || 'Anonymous'"
            max="30"
          />
          <empty v-else />
        </v-col>
      </v-row>
      <v-row class="projectDetails-items">
        <!--  OTHER ATTRIBUTES -->
        <v-col
          v-if="
            sessionDetail?.user?.traits &&
            Object.keys(sessionDetail?.user?.traits).length
          "
          :cols="fromOverview ? 6 : 12"
        >
          <div class="mb-3 px-5 pt-5 pb-5 semi-bold">TRAITS</div>

          <v-row
            v-for="(item, index) in Object.keys(otherAttributes)"
            :key="index"
            class="projectDetails-items pt-5 px-5"
          >
            <v-col class="pa-0 px-5 text-capitalize" cols="5"
              >{{ item }}:</v-col
            >
            <v-col class="semi-bold ma-0 pa-0" cols="7">
              {{ otherAttributes[item] }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col class="py-0" cols="12">
      <v-divider></v-divider>
    </v-col>

    <!-- APP -->

    <v-col :cols="fromOverview ? 6 : 12">
      <div class="mb-3 px-5 pb-5 semi-bold">
        APP
        <v-btn @click="showAppHelp = true" icon>
          <v-icon v-text="'mdi-help-circle-outline'"></v-icon>
        </v-btn>
      </div>
      <v-row class="projectDetails-items pt-5 px-5">
        <v-col class="pa-0 px-5" cols="5"> Name:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.app?.name }}
        </v-col>
      </v-row>

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> Package:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.app?.package }}
        </v-col>
      </v-row>

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> Version:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          v{{ sessionDetail?.context?.app?.version }} ({{
            sessionDetail?.context?.app?.version_code
          }})
        </v-col>
      </v-row>

      <v-row class="projectDetails-items mb-1 px-5">
        <v-col class="pa-0 px-5" cols="5"> Environment:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.app?.environment }}
        </v-col>
      </v-row>
    </v-col>

    <v-col v-if="!fromOverview" class="py-0" cols="12">
      <v-divider></v-divider>
    </v-col>

    <!-- DEVICES -->
    <v-col :cols="fromOverview ? 6 : 12">
      <div class="justify-space-between">
        <div class="mb-3 px-5 pt-5 pb-5 semi-bold">
          DEVICE

          <div
            class="float-right primary--text regular-font font14 cursor-pointer"
            @click="toggleDevices"
          >
            {{ showDeviceExtraAttributes ? "View less" : "View more" }}
          </div>
        </div>
      </div>

      <v-row class="projectDetails-items pt-5 px-5">
        <v-col class="pa-0 px-5" cols="5"> OS:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          <v-icon
            :color="
              sessionDetail?.context?.device?.os?.name === 'android'
                ? 'green'
                : 'grey'
            "
            left
            small
            >{{
              sessionDetail?.context?.device?.os?.name === "android"
                ? "mdi-android"
                : "mdi-apple"
            }}
          </v-icon>
        </v-col>
      </v-row>

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> Model:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.device?.model }}
        </v-col>
      </v-row>

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> Type:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.device?.type }}
        </v-col>
      </v-row>

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> Timezone:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.device?.timezone }}
        </v-col>
      </v-row>

      <template v-if="showDeviceExtraAttributes">
        <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> RAM:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            {{ formatBytes((sessionDetail?.context?.device?.ram || 0) / 1000) }}
          </v-col>
        </v-row>

        <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> CPU:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            {{ sessionDetail?.context?.device?.cores }} Cores
          </v-col>
        </v-row>

        <!-- <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> Storage:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            {{
              formatBytes((sessionDetail?.context?.device?.storage || 0) / 1000)
            }}
          </v-col>
        </v-row> -->

        <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> Locale:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            {{ sessionDetail?.context?.device?.locale }}
          </v-col>
        </v-row>

        <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> Screen:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            {{ sessionDetail?.context?.device?.screen?.width }}w,
            {{ sessionDetail?.context?.device?.screen?.height }}h,
            {{ sessionDetail?.context?.device?.screen?.density }}d
          </v-col>
        </v-row>

        <!-- <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> Push Enabled:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            <v-icon
              :color="
                sessionDetail?.context?.device?.push_enabled
                  ? 'success'
                  : 'error'
              "
              v-text="
                sessionDetail?.context?.device?.push_enabled
                  ? 'mdi-check'
                  : 'mdi-close'
              "
            ></v-icon>
          </v-col>
        </v-row> -->

        <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> Simulator:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            <v-icon
              :color="
                sessionDetail?.context?.device?.simulator ? 'success' : 'error'
              "
              v-text="
                sessionDetail?.context?.device?.simulator
                  ? 'mdi-check'
                  : 'mdi-close'
              "
            ></v-icon>
          </v-col>
        </v-row>

        <!-- <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> Push Token:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            {{ sessionDetail?.context?.device?.push_token || "-" }}
          </v-col>
        </v-row> -->

        <v-row class="projectDetails-items px-5">
          <v-col class="pa-0 px-5" cols="5"> UUID:</v-col>

          <v-col class="semi-bold ma-0 pa-0" cols="7">
            {{ sessionDetail?.context?.device?.uuid }}
          </v-col>
        </v-row>
      </template>
    </v-col>

    <v-col class="py-0" cols="12">
      <v-divider></v-divider>
    </v-col>

    <!-- SDK -->

    <v-col :cols="fromOverview ? 6 : 12">
      <div class="mb-3 px-5 pb-5 semi-bold">SDK</div>
      <v-row class="projectDetails-items pt-5 px-5">
        <v-col class="pa-0 px-5" cols="5"> Name:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.sdk?.name }}
        </v-col>
      </v-row>

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> Version:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.sdk?.version }}
        </v-col>
      </v-row>

      <v-row class="projectDetails-items px-5">
        <v-col class="pa-0 px-5" cols="5"> Version Code:</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.sdk?.version_code }}
        </v-col>
      </v-row>

      <v-row class="projectDetails-items mb-1 px-5">
        <v-col class="pa-0 px-5" cols="5"> User Agent</v-col>

        <v-col class="semi-bold ma-0 pa-0" cols="7">
          {{ sessionDetail?.context?.user_agent }}
        </v-col>
      </v-row>
    </v-col>
    <Modal closeable v-model="showAppHelp">
      <template #card>
        <v-card outlined>
          <v-card-title class="headline text-center"
            >App Information Details</v-card-title
          >
          <v-card-text class="font-14">
            <v-row>
              <v-col cols="12">
                <p>
                  <strong>Name:</strong> The unique name of your app as
                  registered in the SDK. <br />Example: <em>Example App</em>.
                </p>
                <p>
                  <strong>Package (Bundle ID):</strong> The Bundle Identifier
                  for your iOS app, usually in reverse domain name format.
                  <br />Example: <em>testapp.io.example-app</em>.
                </p>
                <p>
                  <strong>Version (Build Number):</strong> The Build Number of
                  your app, representing a specific iteration of your app.
                  <br />Example: <em>v1.0 (106)</em> where 106 is the Build
                  Number.
                </p>
                <p>
                  <strong>Environment:</strong> Set during the setup function
                  and indicates the app's current running environment. Options
                  include <code>dev</code>, <code>staging</code>, and
                  <code>production</code>. <br />Default: <em>dev</em>
                </p>
                <p>
                  These details are vital for accurately tracking and managing
                  different versions and configurations of your app during
                  development, testing, and production phases.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? '800' : ''"
      closeable
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      v-model="showIdentifierHelp"
    >
      <template #card>
        <v-card outlined>
          <v-card-title class="headline text-center"
            >User Identification</v-card-title
          >
          <v-card-text class="font-16">
            <session-code-example></session-code-example>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
    <Modal closeable v-model="showLocationHelp">
      <template #card>
        <v-card outlined>
          <v-card-title class="headline text-center"
            >Tester Location via IP Address</v-card-title
          >
          <v-card-text class="font-14">
            <v-row>
              <v-col cols="12">
                <p>
                  Our platform determines and displays each tester's country and
                  city based on their IP address. This helps developers
                  understand geographical usage patterns and tailor their
                  applications accordingly.
                </p>
                <p>
                  However, it's important to note that this method isn't always
                  precise. IP address locations can be affected by factors like
                  VPNs, proxy servers, and mobile network changes. While
                  generally reliable, developers should be aware of these
                  potential inaccuracies.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import { defineComponent } from "vue";
import { formatBytes } from "@/core/services/helper.service";
import SessionCodeExample from "@/view/components/Sessions/CodeSnippet.vue";

export default defineComponent({
  name: "SessionDetail",
  components: { SessionCodeExample },
  props: {
    sessionDetail: {
      type: Object,
      default: () => {},
    },
    fromOverview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDeviceExtraAttributes: false,
      showAllOtherAttributes: false,
      showAppHelp: false,
      showIdentifierHelp: false,
      showLocationHelp: false,
      formatBytes,
    };
  },
  methods: {
    toggleDevices() {
      this.showDeviceExtraAttributes = !this.showDeviceExtraAttributes;
    },
  },
  computed: {
    otherAttributes() {
      return this.sessionDetail?.user?.traits;
    },
  },
});
</script>

<style scoped>
.px-5 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pt-5 {
  padding-top: 10px !important;
}

.pb-5 {
  padding-bottom: 10px !important;
}

.list-margin {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.projectDetails-items {
  padding-bottom: 10px !important;
}

.code-snippet code {
  white-space: pre-wrap;
}

.code-snippet {
  border-radius: 4px;
  padding: 10px;
}
</style>
